

body {
    background: url('./miron_img/background.png');
    font-family: 'Open Sans';
    font-style: normal;
}

h1 {
    font-weight: 700;
    font-size: 2rem;
    color: #F7F7F7;
}


.header {
    width: 100vw;
    /* height: 5vh; */
    /* margin: 30px 60px 20px 60px; */
}
.header__logo {
    text-align: center;
    margin: 30px 60px 15px 60px;
}
.filter {
    height: 100%;
    width: 100%;
    font-size: 0.5rem;
    margin: 0 0 1.5rem 0;
    -khtml-user-select: none;
    user-select: none;
}
.filter__sort, .filter__stars {
    height: 2.75rem; 
    padding: 8px 20px;
    background: rgba(255, 255, 255, 0.34);
    border: 1px solid rgba(255, 255, 255, 0.34);
    display: flex;
    justify-content: space-between;
}

.sort__title, .stars__title {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.25rem;
    color: #F7F7F7;
    opacity: 1;
    align-self: center;
}

.sort__body, .stars__body {
    display: flex;
    align-items: center;
    gap: 1.25rem;
}
.sort__item, .stars__item {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.25rem;
    color: #F7F7F7;
    opacity: 0.6;
    text-transform: uppercase;
    cursor: pointer;
    padding: 10px;
    margin: -10px;
    position: relative;
}
.sort__item:hover {
    opacity: 1;
}
.sort__item._active {
    opacity: 1;
}
.sort__item._active::after {
    content: ' ';
    width: 24%;
    height: 2px;
    background-color: #FAFF00;
    position: absolute;
    bottom: 4px;
    left: calc(50% - 14%);
}

.center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.info__item {
    opacity: 0.9;
    text-transform: none;
}

.settings_textbox {
    padding: 1px 1px 1px 1px   ;
    /* margin-left: 100; */
    font-weight: 700;
    font-size: 1rem;
    line-height: 1rem;
    color: #030303;
    opacity: 1;
    align-self: center;
    width: fill-available;
}

.settings_label {
    width: 100%;
    /* padding: 1px 1px 10px 10px; */
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.25rem;
    color: #F7F7F7;
    opacity: 1;
    align-self: center;
}

.level__link {
    -khtml-user-select: none;
    user-select: none;
    display: block;
    height: 2.75rem; 
    padding: 8px 20px;
    background: rgba(255, 255, 255, 0.34);
    border: 1px solid rgba(255, 255, 255, 0.34);
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #F7F7F7;
    font-weight: 700;
    font-size: 0;
}

.level__link__current {
    background: rgba(122, 255, 122, 0.34);
}

.level__link__unlocked {
    background: rgba(125, 125, 125, 0.34);
    color: #777777;
}

.level__link:not(.level__link__unlocked):hover>* {
    transform: scale(1.2);
}
.level__number, .level__grid {
    line-height: 1.5rem;
    font-size: 1.25rem;
}
.level__number {
    text-align: left;
    width: 100px;
}
.level__grid {
    text-align: right;
    width: 100px;
}
.level__points {
    height: 18px;
    width: 100px;
    text-align: center;
}

.level__stars-item:not(:last-child) {
    margin-right: 3px;
}


main {
    max-width: 800px;
    margin: 0 auto;
}

.topMenuButton {
    display: block;
    position: absolute;
    width: 50px;
    height: 50px;
    background: #F6F6F6;
    border-radius: 50%;
    text-align: center;
    top: 25px;
}

.settingsButton {
    left: 10px;
}

.infoButton {
    left: 70px;
}

.discordButton {
    right: 10px;
}

.topMenuButton:hover {
    background: #dbdbdb;
}

.settings_form_button {
    display: block;
    /* position: absolute; */
    width: 150px;
    /* height: 50px; */
    background: #F6F6F6;
    border-radius: 10%;
    /* padding-left: 10px; */
    text-align: center;
    top: 25px;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25rem;
}


.info__stats {
    -khtml-user-select: none;
    user-select: none;
    /* display: block; */
    /* height: 2.75rem;  */
    /* padding: 8px 20px; */
    background: rgba(255, 255, 255, 0.34);
    border: 1px solid rgba(255, 255, 255, 0.34);
    /* display: flex; */
    /* justify-content: space-between; */
    align-items: center;
    color: #F7F7F7;
    /* font-weight: 700; */
    /* font-size: 0; */
}

.settings_extra_text {
    /* background: rgba(255, 255, 255, 0.9); */
    margin-left: 10px;
    font-size: 0.7rem;
    /* color: #f3f3f3; */
    padding: 0px 20px;
    font-weight: 600;
    /* line-height: 1.25rem; */
}

/* Styles for the message box container */
.message-box {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background:rgba(128, 255, 128, 0.84);
    font-size:100%;
    padding: 16px;
    z-index: 9999;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  /* Styles for the close button */
  .message-box .close-button {
    position: absolute;
    background:rgba(230, 255, 230, 0.84);
    border: #030303;
    top: 8px;
    right: 8px;
    font-size: 16px;
    font-weight: bold;
    color: #333;
    cursor: pointer;
  }
  
  /* Styles for the message text */
  .message-box p {
    margin: 0;
  }
  