body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

nav {
  font-size: min(5vmin, 2em);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

#canvas-game {
	position: absolute;
	top: 0;
	left: 0;
}

canvas {
  touch-action: none;
}

.main {
  margin: 0 auto;
  max-width: 800px;
  text-align: center;
}

nav {
  background-color: lightgray;
  padding: 10px;
}

nav a {
  color: black;
  text-decoration: none;
  font-size: 18px;
}

h1 {
  font-size: 32px;
  margin: 20px 0;
}

h3 {
  font-size: 24px;
  margin: 20px 0;
  color: #f7f7f7
}


p {
  font-size: 18px;
  line-height: 1.5;
  margin: 20px 0;
  color: #d7d7d7;
}

a {
  color: #d7d7f7;
  text-decoration: none;
}